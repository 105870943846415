import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StoreTool<T> {
  private _state: BehaviorSubject<T>;

  constructor(@Inject('') initialState: T) {
    this._state = new BehaviorSubject<T>(initialState);
  }

  private get state$(): Observable<T> {
    return this._state.asObservable();
  }

  private get state(): T {
    return this._state.getValue();
  }

  public selectState<K>(selector: (state: T) => K): Observable<K> {
    return this.state$.pipe(map(selector), distinctUntilChanged());
  }

  public updateItemArray<T = any>(dataArray: T[], data: any, key: string): T[] {
    const copyDataArray = [...dataArray];
    const index = copyDataArray.findIndex((value) => (value as any)[key] === data[key]);
    if (index >= 0) {
      copyDataArray.splice(index, 1, data);
    }
    return [...copyDataArray];
  }

  protected deleteItemArray<T = any>(dataArray: T[], data: any, key: string): T[] {
    const copyDataArray = [...dataArray];
    const index = copyDataArray.findIndex(
      (value) => (value as any)[key] === data[key]
    );
    if (index >= 0) {
      copyDataArray.splice(index, 1);
    }
    return [...copyDataArray];
  }

  protected setState<K extends keyof T, E extends Partial<Pick<T, K>>>(
    fn: (state: T) => E
  ): void {
    const state = fn(this.state);
    this._state.next({ ...this.state, ...state });
  }
}
